import React, { memo, useMemo, useEffect, useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMatch, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tooltip, Typography, Link } from '@mui/material';
import {
  ActivityEntityType,
  AuditEventType,
  formatDate,
  Routes,
  applicationStatusTypes,
  FileTypes,
} from '../../../lib';
import { caseSelectors, uiActions, uiSelectors } from '../../../state';
import { Loader } from '../..';
import {
  analyticsSelectors,
  residentSelectors,
  analyticsActions,
} from '../../../state';
import {
  AssignmentIndOutlinedIcon,
  FileIcon,
  StickyNoteOutlinedIcon,
  TaskAltOutlinedIcon,
} from '../../../themes';
import { FlexBetween, NameWithStatusAndHover } from '../..';
import { formatDistance } from 'date-fns';
import { DocumentViewModal } from '../../modals/DocumentViewModal';

const defaultNumRows = 15;
export const ActivityFeed = memo(function (props) {
  const { facilityIDs, containerId } = props;
  // hooks
  const dispatch = useDispatch();
  const { id } = useParams();
  const isDashboard = useMatch(Routes.dashboard.path);

  // selectors
  const isOverlayOpen = useSelector(uiSelectors.isOverlayOpen);
  const isPreviewOpen = useSelector(uiSelectors.isPreviewOpen);
  const previewId = useSelector(uiSelectors.previewId);
  const activity = useSelector(analyticsSelectors.activity);
  const reduxCaseID = useSelector(residentSelectors.residentCaseId);
  const reduxResID = useSelector(residentSelectors.residentId);
  const refreshActivityFeed = useSelector(uiSelectors.refreshActivityFeed);
  const people = useSelector(caseSelectors.casePeople);

  // state
  const [caseId, setCaseId] = useState();
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [hasMore, setHasMore] = useState(
    activity?.numberOfRows > defaultNumRows,
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [residentIDs, setResidentIDs] = useState([]);
  const [previewFile, setPreviewFile] = useState(null);

  const handleLoadMore = useCallback(
    async (page) => {
      setPageNumber(page);
      await dispatch(
        analyticsActions.getResidentActivity({
          residentIDs,
          facilityIDs,
          pageNumber: page,
        }),
      );
    },
    [residentIDs, facilityIDs, dispatch],
  );

  const refreshActivity = useCallback(async () => {
    setInitialDataLoaded(false);

    await handleLoadMore(1);

    setInitialDataLoaded(true);
  }, [handleLoadMore]);

  useEffect(() => {
    setCaseId(parseInt(id) || previewId);
  }, [id, previewId]);

  useEffect(() => {
    if (
      //if this is case-specific, don't fetch until the data lines up (when navigating between cases)
      residentIDs.length > 0 &&
      reduxCaseID === caseId &&
      reduxResID === residentIDs[0]
    ) {
      refreshActivity();
    }
  }, [refreshActivity, residentIDs, caseId, reduxCaseID, reduxResID]);

  //handle the dashboard in its own useEffect so the other factors don't cause it to run again
  useEffect(() => {
    if (isDashboard) {
      refreshActivity();
    }
  }, [isDashboard, refreshActivity]);

  useEffect(() => {
    if (isOverlayOpen || isPreviewOpen) {
      setResidentIDs(people.map((r) => r.id));
    }
  }, [people, isOverlayOpen, isPreviewOpen]);

  useEffect(() => {
    if (refreshActivityFeed) {
      refreshActivity();
      dispatch(uiActions.setRefreshActivityFeed(false));
    }
  }, [refreshActivityFeed, dispatch, refreshActivity]);

  useEffect(() => {
    setHasMore(pageNumber < Math.ceil(activity?.numberOfRows / defaultNumRows));
  }, [activity, pageNumber]);

  const showName = residentIDs.length > 1 || isDashboard;

  if (!initialDataLoaded) return <Loader />;

  return (
    <div>
      {activity?.results?.length > 0 ? (
        <InfiniteScroll
          dataLength={activity.results.length}
          hasMore={hasMore}
          next={() => handleLoadMore(pageNumber + 1)}
          loader={<Loader />}
          scrollableTarget={containerId}
        >
          {activity.results.map((d, i) => (
            <ActivityListItem
              key={i}
              {...d}
              showName={showName}
              isOverlayOpen={isOverlayOpen}
              setPreviewFile={setPreviewFile}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <Box textAlign='center' marginTop={10}>
          <img src='/images/no-notes.svg' alt='' height='96' />
          <Typography component={'span'} display='block' mt={2}>
            No activity yet
          </Typography>
          <Typography
            component={'span'}
            display='block'
            mt={1}
            fontSize={12}
            color={'text.secondary'}
          >
            Start working on the case
          </Typography>
        </Box>
      )}
      {previewFile && (
        <DocumentViewModal
          open={true}
          handleClose={() => setPreviewFile(null)}
          file={previewFile}
        />
      )}
    </div>
  );
});

export function ActivityIcon({ activityType }) {
  const { Icon, color } = useMemo(() => {
    switch (activityType) {
      case ActivityEntityType.Application:
        return { Icon: AssignmentIndOutlinedIcon, color: '#FE831A' };
      case ActivityEntityType.Note:
        return { Icon: StickyNoteOutlinedIcon, color: '#FFBD07' };
      case ActivityEntityType.File:
        return { Icon: FileIcon, color: '#096EF8' };
      case ActivityEntityType.Todo:
        return { Icon: TaskAltOutlinedIcon, color: '#6506EE' };
      default:
        return { Icon: AssignmentIndOutlinedIcon, color: 'text.secondary' };
    }
  }, [activityType]);

  return (
    <Box
      sx={{
        mt: 0.25,
        height: 18,
        width: 18,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 0.25,
        backgroundColor: color,
        color: '#FFF',
        borderRadius: '3px',
        '& .MuiSvgIcon-root': {
          maxWidth: 16,
          maxHeight: 16,
        },
      }}
    >
      <Icon />
    </Box>
  );
}

export function ActivityListItem({
  entityType,
  actionType,
  additionalInfo,
  entityTypeAuditEvent,
  changes = [],
  residentLastName,
  residentFirstName,
  isResidentActive,
  facilityName,
  caseID,
  userFirstName,
  userLastName,
  description,
  fileData,
  timestamp,
  showName,
  isOverlayOpen,
  setPreviewFile,
}) {
  const activityUser = `${userFirstName} ${userLastName} `;
  let title;
  let content;

  /* 
  entityType: use for the icon:
      application
      note
      todo
      file
  actionType:
      modified
      added
  entityTypeAuditEvent:
      taskCreated
      fileCreated 

  //if there is no type, check the property name for IsOutsourced, and the new/old value  

  changes (array)
      auditEvent:
        ApplicationIsOutsourced
        NoteEdit - can also be note created, need to check the actionType on the event itself to see if its modified or added
        TaskCompleted
        ApplicationInfoEdit
      newValue (would be the value of the field to display, usually a string)
  */

  if (entityTypeAuditEvent === AuditEventType.FileCreated) {
    if (!fileData) {
      title = 'added a document';
      content = 'This file has been deleted';
    } else if (fileData.type === FileTypes.External) {
      title = 'added a link';
      content = (
        <Link
          href={fileData.location}
          target='_blank'
          rel='noopener noreferrer'
          underline='none'
        >
          {fileData.customName}
        </Link>
      );
    } else {
      title = 'uploaded a doc';
      content = (
        <Typography
          onClick={() => setPreviewFile(fileData)}
          color='primary'
          style={{ cursor: 'pointer' }}
        >
          {fileData.customName}
        </Typography>
      );
    }
  } else if (entityType === ActivityEntityType.Note) {
    title = `${actionType === 'Added' ? 'added' : 'updated'} a note`;
    content = changes.map((c) => c.newValue);
  } else if (entityType === ActivityEntityType.Todo) {
    if (entityTypeAuditEvent === AuditEventType.TaskCreated) {
      //for legacy records, there will be no changes and this is the only piece of identifying data that we have
      title = 'created a task';
    } else {
      title = 'updated a task';
    }

    const contentList = [];
    changes.forEach((c) => {
      if (c.auditEvent === AuditEventType.TaskCompleted) {
        if (
          c.property?.propertyName === 'DoneOn' &&
          !c.newValue &&
          c.oldValue
        ) {
          contentList.push('Done → Todo');
        } else {
          title = 'completed a task'; //when a task is completed, title is always this (even if its a create)
        }
      } else if (c.property.propertyName === 'TaskTitle') {
        contentList.push(c.newValue); //this is the task title
      }
    });

    //always show the due date if we have it (legacy records will not have it)
    if (additionalInfo) {
      contentList.push(formatDate(additionalInfo.dueDate));
    }

    content = contentList.join(' | ');
  } else if (entityType === ActivityEntityType.Application) {
    //check if this is InfoEdit or Outsourced.
    //It can have more than one change at a time since we are tracking two fields for one 'save' event
    const isCreate = actionType === 'Added';
    title = `${isCreate ? 'created' : 'updated'} an application`;
    content = changes
      .map((c) =>
        c.auditEvent === AuditEventType.ApplicationIsOutsourced
          ? c.newValue === 'False' && c.oldValue === 'True'
            ? 'Not outsourced'
            : c.newValue === 'True' && c.oldValue !== 'True'
            ? 'Outsourced'
            : null
          : c.auditEvent === AuditEventType.ApplicationInfoEdit
          ? `info: ${c.newValue}`
          : c.property?.propertyName === 'Status'
          ? `Status ${
              isCreate
                ? `set to ${getApplicationStatusDisplayValue(c.newValue)}`
                : `changed: ${getApplicationStatusDisplayValue(
                    c.oldValue,
                  )} → ${getApplicationStatusDisplayValue(c.newValue)}`
            }`
          : '',
      )
      .filter((i) => i)
      .join(' | ');
  }

  const fullName = `${residentFirstName} ${residentLastName}`;

  return (
    <Box
      sx={{
        p: 2,
        borderBottom: '1px solid rgba(9, 27, 52, 0.16)',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <ActivityIcon activityType={entityType} />
        <Box sx={{ ml: 1.25, flexGrow: 1 }}>
          <Box sx={{ mb: 0.5, fontWeight: 600 }}>
            {activityUser}
            {title}
          </Box>
          <Box sx={{ mb: 1 }}>{content}</Box>
          <FlexBetween alignItems='baseline'>
            {showName ? (
              <NameWithStatusAndHover
                residentName={fullName}
                isResidentActive={isResidentActive}
                facilityName={facilityName}
                {...(!isOverlayOpen && { caseId: caseID })}
              />
            ) : (
              // placeholder so the date is still on the right
              <span />
            )}

            <Tooltip
              title={`${formatDate(timestamp, 'MMM d, y')} at ${formatDate(
                timestamp,
                'p',
              )}`}
              placement={'top'}
            >
              <Box textAlign='right' lineHeight={1}>
                <Typography variant='label' color='text.secondary'>
                  {formatDistance(new Date(timestamp), new Date(), {
                    addSuffix: true,
                  })}
                </Typography>
              </Box>
            </Tooltip>
          </FlexBetween>
        </Box>
      </Box>
    </Box>
  );
}

function getApplicationStatusDisplayValue(status) {
  switch (status) {
    case applicationStatusTypes.AdjustmentNeeded:
      return 'Adjustment Needed';
    case applicationStatusTypes.NotPursuing:
      return 'Not Pursuing';
    case applicationStatusTypes.PendingApproval:
      return 'Pending Approval';
    default:
      return status;
  }
}
